<template>
    <div class="relative w-full min-h-[100dvh] flex flex-col justify-between bg-neutral-100 dark:bg-neutral-900">
        <div class="conteudo py-12 flex items-center">
            <a href="/" class="group flex items-center space-x-2">
                <div class="w-10 h-10 flex items-center justify-center bg-opacity-0 bg-verde-200 group-hover:bg-opacity-100 rounded-full overflow-hidden transition-all duration-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
</svg>

</div>
                <div class="text-xs font-bold uppercase opacity-0 group-hover:opacity-100 transition-all duration-500">Website</div>
</a>
        </div>
        <div class="flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
            <div>
                <slot name="logo" />
            </div>

            <div class="w-full sm:max-w-md mt-6 px-6 py-4 bg-white dark:bg-neutral-800 shadow-md overflow-hidden sm:rounded-lg">
                <slot />
            </div>
        </div>
        <div class="conteudo py-12 flex justify-center items-center">
            <div class="text-xs"><strong>©2024 Made of You Group</strong> | All rights reserved</div>
        </div>
    </div>
</template>
